import moment from 'moment';

export const getChargeType = sumemPoint => {
  if (sumemPoint.contractId) {
    if (sumemPoint.amount > 0) {
      return '소진';
    } else if (sumemPoint.amount < 0) {
      return '환불';
    } else {
      return '-';
    }
  } else {
    if (sumemPoint.pointType === '충전') {
      return '충전';
    } else if (sumemPoint.pointType === '차감') {
      return '차감';
    } else {
      return '-';
    }
  }
};

export async function saveScreenshot(screenshot, name = '', rank, date = new Date()) {
  const filename = `${name}_rank_${rank}_${moment(date).format('YYYYMMDD')}.png`;

  const response = await fetch(screenshot);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export const getContractStatusText = contract => {
  const { currentState, rewardStatus, first, selectedAt, canceledReason } = contract;

  if (currentState === -3) {
    return '선정완료';
  }

  if (currentState === -2) {
    return '선정대기';
  }

  if (currentState === -1) {
    if (!selectedAt) {
      return '직접 취소';
    }
    return canceledReason ? '구매 미인증' : '이용취소';
  }

  if (currentState === 0) {
    if (rewardStatus === 0) {
      return '구매완료';
    }
    return first === '매장' ? '이용완료' : '신청완료';
  }

  if (currentState === 1) {
    return '리뷰등록';
  }

  if (currentState === 2) {
    return '평가완료';
  }

  return null;
};
